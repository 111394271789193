.container {  
  height: 100%;
  width: 100%;  
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 200px;
}

.title {  
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;   
  font-size: 18px;  
}

.mais {
  font-weight: bold;
  font-size: 36px;
}

.informarQtdPreco {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: 32px;  
  font-weight: bold;
}

.numeros3 {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}

.button {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonTop {
  position: absolute;
  padding-left: 80%;
  padding-top: 5%;
  border-style: "solid";
}

.calculadoraInformaPreco { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60%;
  min-height: 70%;
}

.buttonCalculadora {
  color: black;
  background-color: inherit;
  border: none;
  font-size: 22px;
  opacity: 1;
  width: 60px;
  height: 40px;
}

.numeros {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  color: rebeccapurple; 
  font-size: 12px;  
}

.numerosButton {
  width: 100%;
  color: beige;  
}
